import React from "react";
import NavigationTab from "components/NavigationTab";
import { tabs } from "./constants";

const Photojournalism = () => {
  return (
    <>
      <NavigationTab tabs={tabs} />
    </>
  );
};

export default Photojournalism;
